export default [
  {
    "0": "OJP3/A/P0/U1",
    "1": "A",
    "3": "P0",
    "4": 31.31,
    "5": 2,
    "6": "Przyjazny dla niepełnosprawnych",
    "7": "IV kwartał 2025",
    "11": "/assets/files/ojp3/pdf/OFNW8E_A.PDF",
    "12": "small",
    "13": "",
    "14": "service",
    tour: "",
    order: 1,
  },
  {
    "0": "OJP3/A/P0/01",
    "1": "A",
    "3": "P0",
    "4": 44.74,
    "5": 2,
    "6": "ogródek",
    "7": "IV kwartał 2025",
    "11": "/assets/files/ojp3/pdf/OJP3-A-P0-01_Umowa_Rezerwacyjna.pdf",
    "12": "medium",
    "13": "true",
    "14": "residential",
    tour: "",
    order: 2,
  },
  {
    "0": "OJP3/A/P0/02",
    "1": "A",
    "3": "P0",
    "4": 28.97,
    "5": 1,
    "6": "ogródek",
    "7": "IV kwartał 2025",
    "11": "/assets/files/ojp3/pdf/OJP3-A-P0-02_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "true",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/338e47cd-a33f-4460-b9f1-801067fa23ca.3d",
    order: 3,
  },
  {
    "0": "OJP3/A/P0/03",
    "1": "A",
    "3": "P0",
    "4": 28.95,
    "5": 1,
    "6": "ogródek",
    "7": "IV kwartał 2025",
    "11": "/assets/files/ojp3/pdf/OJP3-A-P0-03_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "true",
    "14": "residential",
    // price: "287 130 zł",
    tour:
      "https://tours.3destate.pl/Process/c1da9a2d-e97f-40f1-b767-3348c808d593.3d",
    order: 5,
  },
  {
    "0": "OJP3/A/P0/04",
    "1": "A",
    "3": "P0",
    "4": 29.61,
    "5": 1,
    "6": "ogródek",
    "7": "IV kwartał 2025",
    "11": "/assets/files/ojp3/pdf/OJP3-A-P0-04_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "true",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/c0c82127-e525-4e4a-895b-9e4010c7630b.3d",
    order: 6,
  },
  {
    "0": "OJP3/A/P0/05",
    "1": "A",
    "3": "P0",
    "4": 28.99,
    "5": 1,
    "6": "ogródek",
    "7": "IV kwartał 2025",
    "11": "/assets/files/ojp3/pdf/OJP3-A-P0-05_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "true",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/7317ffbd-3db7-4ebe-8bd6-ac9f5748c003.3d",
    order: 8,
  },
  {
    "0": "OJP3/A/P0/06",
    "1": "A",
    "3": "P0",
    "4": 63.3,
    "5": 3,
    "6": "dwa ogrody",
    "7": "IV kwartał 2025",
    "11": "/assets/files/ojp3/pdf/OJP3-A-P0-06_Umowa_Rezerwacyjna.pdf",
    "12": "large",
    "13": "true",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/6fb91aae-d59c-42d4-983d-59037d7cbfbf.3d",
    order: 9,
  },
  {
    "0": "OJP3/A/P0/07",
    "1": "A",
    "3": "P0",
    "4": 27.68,
    "5": 1,
    "6": "ogródek",
    "7": "IV kwartał 2025",
    "11": "/assets/files/ojp3/pdf/OJP3-A-P0-07_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "true",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/aa42b378-67c6-4674-ac21-61cf02df25a6.3d",
    order: 11,
  },
  {
    "0": "OJP3/A/P0/08",
    "1": "A",
    "3": "P0",
    "4": 49.22,
    "5": 3,
    "6": "ogródek",
    "7": "IV kwartał 2025",
    "11": "/assets/files/ojp3/pdf/OJP3-A-P0-08_Umowa_Rezerwacyjna.pdf",
    "12": "medium",
    "13": "true",
    "14": "residential",
    // price: "432 006 zł",
    tour:
      "https://tours.3destate.pl/Process/0b5c8884-7887-48e2-a063-70a562e9b60d.3d",
    order: 12,
  },
  {
    "0": "OJP3/A/P0/09",
    "1": "A",
    "3": "P0",
    "4": 52.71,
    "5": 3,
    "6": "ogródek",
    "7": "IV kwartał 2025",
    "11": "/assets/files/ojp3/pdf/OJP3-A-P0-09_Umowa_Rezerwacyjna.pdf",
    "12": "large",
    "13": "true",
    "14": "residential",
    tour: "",
    order: 14,
  },
  {
    "0": "OJP3/A/P0/10",
    "1": "A",
    "3": "P0",
    "4": 38.68,
    "5": 2,
    "6": "ogródek",
    "7": "IV kwartał 2025",
    "11": "/assets/files/ojp3/pdf/OJP3-A-P0-10_Umowa_Rezerwacyjna.pdf",
    "12": "medium",
    "13": "true",
    "14": "residential",
    // price: "327 697 zł",
    tour: "",
    order: 15,
  },
  {
    "0": "OJP3/A/P0/11",
    "1": "A",
    "3": "P0",
    "4": 28.52,
    "5": 1,
    "6": "ogródek",
    "7": "IV kwartał 2025",
    "11": "/assets/files/ojp3/pdf/OJP3-A-P0-11_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "true",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/57d27b28-7346-4699-b27f-d878c4c01608.3d",
    order: 17,
  },
  {
    "0": "OJP3/A/P0/12",
    "1": "A",
    "3": "P0",
    "4": 27.72,
    "5": 1,
    "6": "ogródek",
    "7": "IV kwartał 2025",
    "11": "/assets/files/ojp3/pdf/OJP3-A-P0-12_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "true",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/144bbf47-ff3a-4ad0-afdf-244cba06a78f.3d",
    order: 18,
  },

  {
    "0": "OJP3/A/P1/13",
    "1": "A",
    "3": "P1",
    "4": 44.74,
    "5": 2,
    "6": "balkon",
    "7": "IV kwartał 2025",
    "11": "/assets/files/ojp3/pdf/OJP3-A-P1-13_Umowa_Rezerwacyjna.pdf",
    "12": "medium",
    "13": "true",
    "14": "residential",
    tour: "",
    order: 20,
  },
  {
    "0": "OJP3/A/P1/14",
    "1": "A",
    "3": "P1",
    "4": 28.97,
    "5": 1,
    "6": "balkon",
    "7": "IV kwartał 2025",
    "11": "/assets/files/ojp3/pdf/OJP3-A-P1-14_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "",
    "14": "residential",
    price: "296 913 zł",
    tour:
      "https://tours.3destate.pl/Process/a0c393f6-77e2-46dc-ae87-1bdde95b35cb.3d",
    order: 4,
  },
  {
    "0": "OJP3/A/P1/15",
    "1": "A",
    "3": "P1",
    "4": 28.95,
    "5": 1,
    "6": "balkon",
    "7": "IV kwartał 2025",
    "11": "/assets/files/ojp3/pdf/OJP3-A-P1-15_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "",
    "14": "residential",
    // price: "282 272 zł",
    tour:
      "https://tours.3destate.pl/Process/9bb38087-8cfe-43fe-9efc-3304fee735cf.3d",
    order: 7,
  },
  {
    "0": "OJP3/A/P1/16",
    "1": "A",
    "3": "P1",
    "4": 29.61,
    "5": 1,
    "6": "balkon",
    "7": "IV kwartał 2025",
    "11": "/assets/files/ojp3/pdf/OJP3-A-P1-16_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "true",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/1779fb61-48d0-49a1-8e95-fb7254cfa6e0.3d",
    order: 10,
  },
  {
    "0": "OJP3/A/P1/17",
    "1": "A",
    "3": "P1",
    "4": 28.99,
    "5": 1,
    "6": "balkon",
    "7": "IV kwartał 2025",
    "11": "/assets/files/ojp3/pdf/OJP3-A-P1-17_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "true",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/1efed9b6-eb4a-4839-82be-5c1f35b81d31.3d",
    order: 21,
  },
  {
    "0": "OJP3/A/P1/18",
    "1": "A",
    "3": "P1",
    "4": 65.3,
    "5": 3,
    "6": "balkon",
    "7": "IV kwartał 2025",
    "11": "/assets/files/ojp3/pdf/OJP3-A-P1-18_Umowa_Rezerwacyjna.pdf",
    "12": "large",
    "13": "",
    "14": "residential",
    // price: "581 891 zł",
    tour:
      "https://tours.3destate.pl/Process/d65e8378-c7f7-4503-97cd-d4cb29f3c554.3d",
    order: 13,
  },
  {
    "0": "OJP3/A/P1/19",
    "1": "A",
    "3": "P1",
    "4": 27.68,
    "5": 1,
    "6": "balkon",
    "7": "IV kwartał 2025",
    "11": "/assets/files/ojp3/pdf/OJP3-A-P1-19_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "true",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/d821fc18-6228-48f8-9b44-b61f000db2b8.3d",
    order: 23,
  },
  {
    "0": "OJP3/A/P1/20",
    "1": "A",
    "3": "P1",
    "4": 43.87,
    "5": 2,
    "6": "balkon",
    "7": "IV kwartał 2025",
    "11": "/assets/files/ojp3/pdf/OJP3-A-P1-20_Umowa_Rezerwacyjna.pdf",
    "12": "medium",
    "13": "true",
    "14": "residential",
    // price: "421 707 zł",
    tour:
      "https://tours.3destate.pl/Process/f8323a40-5151-404f-a1bd-f0368c085be8.3d",
    order: 24,
  },
  {
    "0": "OJP3/A/P1/21",
    "1": "A",
    "3": "P1",
    "4": 49.22,
    "5": 3,
    "6": "balkon",
    "7": "IV kwartał 2025",
    "11": "/assets/files/ojp3/pdf/OJP3-A-P1-21_Umowa_Rezerwacyjna.pdf",
    "12": "medium",
    "13": "",
    "14": "residential",
    price: "450 363 zł",
    tour:
      "https://tours.3destate.pl/Process/79ad1c37-3784-4d35-ae27-3cf48ba4e376.3d",
    order: 16,
  },
  {
    "0": "OJP3/A/P1/22",
    "1": "A",
    "3": "P1",
    "4": 52.71,
    "5": 3,
    "6": "balkon",
    "7": "IV kwartał 2025",
    "11": "/assets/files/ojp3/pdf/OJP3-A-P1-22_Umowa_Rezerwacyjna.pdf",
    "12": "large",
    "13": "true",
    "14": "residential",
    tour: "",
    order: 26,
  },
  {
    "0": "OJP3/A/P1/23",
    "1": "A",
    "3": "P1",
    "4": 38.68,
    "5": 2,
    "6": "balkon",
    "7": "IV kwartał 2025",
    "11": "/assets/files/ojp3/pdf/OJP3-A-P1-23_Umowa_Rezerwacyjna.pdf",
    "12": "medium",
    "13": "true",
    "14": "residential",
    // price: "377 558 zł",
    tour:
      "https://tours.3destate.pl/Process/5653430e-a7ad-4d4e-a447-7dbf171ceb5d.3d",
    order: 27,
  },
  {
    "0": "OJP3/A/P1/24",
    "1": "A",
    "3": "P1",
    "4": 28.52,
    "5": 1,
    "6": "balkon",
    "7": "IV kwartał 2025",
    "11": "/assets/files/ojp3/pdf/OJP3-A-P1-24_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "true",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/421114a3-75e9-457c-8b95-c7d65e82da37.3d",
    order: 29,
  },
  {
    "0": "OJP3/A/P1/25",
    "1": "A",
    "3": "P1",
    "4": 27.72,
    "5": 1,
    "6": "balkon",
    "7": "IV kwartał 2025",
    "11": "/assets/files/ojp3/pdf/OJP3-A-P1-25_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "",
    "14": "residential",
    price: "284 102 zł",
    tour:
      "https://tours.3destate.pl/Process/3dab8c2e-2499-4664-9400-aa197ca765dd.3d",
    order: 19,
  },

  {
    "0": "OJP3/A/P2/26",
    "1": "A",
    "3": "P2",
    "4": 44.74,
    "5": 2,
    "6": "balkon",
    "7": "IV kwartał 2025",
    "11": "/assets/files/ojp3/pdf/OJP3-A-P2-26_Umowa_Rezerwacyjna.pdf",
    "12": "medium",
    "13": "true",
    "14": "residential",
    tour: "",
    order: 22,
  },
  {
    "0": "OJP3/A/P2/27",
    "1": "A",
    "3": "P2",
    "4": 28.97,
    "5": 1,
    "6": "balkon",
    "7": "IV kwartał 2025",
    "11": "/assets/files/ojp3/pdf/OJP3-A-P2-27_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "true",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/a0c393f6-77e2-46dc-ae87-1bdde95b35cb.3d",
    order: 30,
  },
  {
    "0": "OJP3/A/P2/28",
    "1": "A",
    "3": "P2",
    "4": 28.95,
    "5": 1,
    "6": "balkon",
    "7": "IV kwartał 2025",
    "11": "/assets/files/ojp3/pdf/OJP3-A-P2-28_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "true",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/9bb38087-8cfe-43fe-9efc-3304fee735cf.3d",
    order: 25,
  },
  {
    "0": "OJP3/A/P2/29",
    "1": "A",
    "3": "P2",
    "4": 29.61,
    "5": 1,
    "6": "balkon",
    "7": "IV kwartał 2025",
    "11": "/assets/files/ojp3/pdf/OJP3-A-P2-29_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "true",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/1779fb61-48d0-49a1-8e95-fb7254cfa6e0.3d",
    order: 32,
  },
  {
    "0": "OJP3/A/P2/30",
    "1": "A",
    "3": "P2",
    "4": 28.99,
    "5": 1,
    "6": "balkon",
    "7": "IV kwartał 2025",
    "11": "/assets/files/ojp3/pdf/OJP3-A-P2-30_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "true",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/1efed9b6-eb4a-4839-82be-5c1f35b81d31.3d",
    order: 33,
  },
  {
    "0": "OJP3/A/P2/31",
    "1": "A",
    "3": "P2",
    "4": 65.3,
    "5": 3,
    "6": "balkon",
    "7": "IV kwartał 2025",
    "11": "/assets/files/ojp3/pdf/OJP3-A-P2-31_Umowa_Rezerwacyjna.pdf",
    "12": "large",
    "13": "",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/d65e8378-c7f7-4503-97cd-d4cb29f3c554.3d",
    order: 28,
  },
  {
    "0": "OJP3/A/P2/32",
    "1": "A",
    "3": "P2",
    "4": 27.68,
    "5": 1,
    "6": "balkon",
    "7": "IV kwartał 2025",
    "11": "/assets/files/ojp3/pdf/OJP3-A-P2-32_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "true",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/d821fc18-6228-48f8-9b44-b61f000db2b8.3d",
    order: 35,
  },
  {
    "0": "OJP3/A/P2/33",
    "1": "A",
    "3": "P2",
    "4": 43.87,
    "5": 2,
    "6": "balkon",
    "7": "IV kwartał 2025",
    "11": "/assets/files/ojp3/pdf/OJP3-A-P2-33_Umowa_Rezerwacyjna.pdf",
    "12": "medium",
    "13": "true",
    "14": "residential",
    tour: "",
    order: 36,
  },
  {
    "0": "OJP3/A/P2/34",
    "1": "A",
    "3": "P2",
    "4": 49.22,
    "5": 3,
    "6": "balkon",
    "7": "IV kwartał 2025",
    "11": "/assets/files/ojp3/pdf/OJP3-A-P2-34_Umowa_Rezerwacyjna.pdf",
    "12": "medium",
    "13": "",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/79ad1c37-3784-4d35-ae27-3cf48ba4e376.3d",
    order: 31,
  },
  {
    "0": "OJP3/A/P2/35",
    "1": "A",
    "3": "P2",
    "4": 52.71,
    "5": 3,
    "6": "balkon",
    "7": "IV kwartał 2025",
    "11": "/assets/files/ojp3/pdf/OJP3-A-P2-35_Umowa_Rezerwacyjna.pdf",
    "12": "large",
    "13": "true",
    "14": "residential",
    tour: "",
    order: 37,
  },
  {
    "0": "OJP3/A/P2/36",
    "1": "A",
    "3": "P2",
    "4": 38.68,
    "5": 2,
    "6": "balkon",
    "7": "IV kwartał 2025",
    "11": "/assets/files/ojp3/pdf/OJP3-A-P2-36_Umowa_Rezerwacyjna.pdf",
    "12": "medium",
    "13": "true",
    "14": "residential",
    tour: "",
    order: 39,
  },
  {
    "0": "OJP3/A/P2/37",
    "1": "A",
    "3": "P2",
    "4": 28.52,
    "5": 1,
    "6": "balkon",
    "7": "IV kwartał 2025",
    "11": "/assets/files/ojp3/pdf/OJP3-A-P2-37_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "true",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/421114a3-75e9-457c-8b95-c7d65e82da37.3d",
    order: 34,
  },
  {
    "0": "OJP3/A/P2/38",
    "1": "A",
    "3": "P2",
    "4": 27.72,
    "5": 1,
    "6": "balkon",
    "7": "IV kwartał 2025",
    "11": "/assets/files/ojp3/pdf/OJP3-A-P2-38_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "true",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/10ff5cb0-d265-472a-9db5-fbd833a93830.3d",
    order: 40,
  },

  {
    "0": "OJP3/A/P3/39",
    "1": "A",
    "3": "P3",
    "4": 44.74,
    "5": 2,
    "6": "balkon",
    "7": "IV kwartał 2025",
    "11": "/assets/files/ojp3/pdf/OJP3-A-P3-39_Umowa_Rezerwacyjna.pdf",
    "12": "medium",
    "13": "true",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/5fd6d980-7dd2-41d9-925e-6e0f5e1db202.3d",
    order: 42,
  },
  {
    "0": "OJP3/A/P3/40",
    "1": "A",
    "3": "P3",
    "4": 28.97,
    "5": 1,
    "6": "balkon",
    "7": "IV kwartał 2025",
    "11": "/assets/files/ojp3/pdf/OJP3-A-P3-40_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "true",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/a0c393f6-77e2-46dc-ae87-1bdde95b35cb.3d",
    order: 43,
  },
  {
    "0": "OJP3/A/P3/41",
    "1": "A",
    "3": "P3",
    "4": 28.95,
    "5": 1,
    "6": "balkon",
    "7": "IV kwartał 2025",
    "11": "/assets/files/ojp3/pdf/OJP3-A-P3-41_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "true",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/9bb38087-8cfe-43fe-9efc-3304fee735cf.3d",
    order: 38,
  },
  {
    "0": "OJP3/A/P3/42",
    "1": "A",
    "3": "P3",
    "4": 44.85,
    "5": 2,
    "6": "dwa balkony",
    "7": "IV kwartał 2025",
    "11": "/assets/files/ojp3/pdf/OJP3-A-P3-42_Umowa_Rezerwacyjna.pdf",
    "12": "medium",
    "13": "true",
    "14": "residential",
    tour: "",
  },
  {
    "0": "OJP3/A/P3/43",
    "1": "A",
    "3": "P3",
    "4": 33.06,
    "5": 1,
    "6": "taras",
    "7": "IV kwartał 2025",
    "11": "/assets/files/ojp3/pdf/OJP3-A-P3-43_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "true",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/2a7c3bc3-a555-43d9-8bed-589009eb8497.3d",
  },
  {
    "0": "OJP3/A/P3/44",
    "1": "A",
    "3": "P3",
    "4": 57.01,
    "5": 3,
    "6": "dwa balkony",
    "7": "IV kwartał 2025",
    "11": "/assets/files/ojp3/pdf/OJP3-A-P3-44_Umowa_Rezerwacyjna.pdf",
    "12": "large",
    "13": "true",
    "14": "residential",
    tour: "",
  },
  {
    "0": "OJP3/A/P3/45",
    "1": "A",
    "3": "P3",
    "4": 38.68,
    "5": 2,
    "6": "balkon",
    "7": "IV kwartał 2025",
    "11": "/assets/files/ojp3/pdf/OJP3-A-P3-45_Umowa_Rezerwacyjna.pdf",
    "12": "medium",
    "13": "true",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/5653430e-a7ad-4d4e-a447-7dbf171ceb5d.3d",
  },
  {
    "0": "OJP3/A/P3/46",
    "1": "A",
    "3": "P3",
    "4": 28.52,
    "5": 1,
    "6": "balkon",
    "7": "IV kwartał 2025",
    "11": "/assets/files/ojp3/pdf/OJP3-A-P3-46_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/421114a3-75e9-457c-8b95-c7d65e82da37.3d",
    order: 41,
  },
  {
    "0": "OJP3/A/P3/47",
    "1": "A",
    "3": "P3",
    "4": 27.72,
    "5": 1,
    "6": "balkon",
    "7": "IV kwartał 2025",
    "11": "/assets/files/ojp3/pdf/OJP3-A-P3-47_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "true",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/3dab8c2e-2499-4664-9400-aa197ca765dd.3d",
  },

  {
    "0": "OJP3/B/P0/01",
    "1": "B",
    "3": "P0",
    "4": 36.79,
    "5": 1,
    "6": "ogródek",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-B-P0-01_Umowa_Rezerwacyjna.pdf",
    "12": "medium",
    "13": "",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/8d014229-3f7b-4f47-8c3d-5225844d19ae.3d",
  },
  {
    "0": "OJP3/B/P0/02",
    "1": "B",
    "3": "P0",
    "4": 29.61,
    "5": 1,
    "6": "ogródek",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-B-P0-02_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/c0c82127-e525-4e4a-895b-9e4010c7630b.3d",
  },
  {
    "0": "OJP3/B/P0/03",
    "1": "B",
    "3": "P0",
    "4": 29.61,
    "5": 1,
    "6": "ogródek",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-B-P0-03_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/c0c82127-e525-4e4a-895b-9e4010c7630b.3d",
  },
  {
    "0": "OJP3/B/P0/04",
    "1": "B",
    "3": "P0",
    "4": 28.99,
    "5": 1,
    "6": "ogródek",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-B-P0-04_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/7317ffbd-3db7-4ebe-8bd6-ac9f5748c003.3d",
  },
  {
    "0": "OJP3/B/P0/05",
    "1": "B",
    "3": "P0",
    "4": 38.4,
    "5": 1,
    "6": "ogródek",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-B-P0-05_Umowa_Rezerwacyjna.pdf",
    "12": "medium",
    "13": "",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/a878d7ee-6e2f-4fef-ab57-b29fc1464725.3d",
  },
  {
    "0": "OJP3/B/P0/06",
    "1": "B",
    "3": "P0",
    "4": 39.64,
    "5": 2,
    "6": "ogródek",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-B-P0-06_Umowa_Rezerwacyjna.pdf",
    "12": "medium",
    "13": "",
    "14": "residential",
    tour: "",
  },
  {
    "0": "OJP3/B/P0/07",
    "1": "B",
    "3": "P0",
    "4": 33.38,
    "5": 2,
    "6": "ogródek",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-B-P0-07_Umowa_Rezerwacyjna.pdf",
    "12": "medium",
    "13": "",
    "14": "residential",
    price: '323 773 zł',
    tour: "",
  },
  {
    "0": "OJP3/B/P0/08",
    "1": "B",
    "3": "P0",
    "4": 41.76,
    "5": 2,
    "6": "ogródek",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-B-P0-08_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "true",
    "14": "residential",
    tour: "",
  },
  {
    "0": "OJP3/B/P0/09",
    "1": "B",
    "3": "P0",
    "4": 27.89,
    "5": 1,
    "6": "ogródek",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-B-P0-09_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "true",
    "14": "residential",
    price: '283 494 zł',
    tour:
      "https://tours.3destate.pl/Process/884e4e2b-76b0-4409-9a27-8fa2d0a872ba.3d",
  },
  {
    "0": "OJP3/B/P0/10",
    "1": "B",
    "3": "P0",
    "4": 40.78,
    "5": 2,
    "6": "ogródek",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-B-P0-10_Umowa_Rezerwacyjna.pdf",
    "12": "medium",
    "13": "",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/6000682a-6ed2-496f-beed-1dba3b49e0d1.3d",
  },

  {
    "0": "OJP3/B/P1/11",
    "1": "B",
    "3": "P1",
    "4": 36.79,
    "5": 1,
    "6": "balkon",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-B-P1-11_Umowa_Rezerwacyjna.pdf",
    "12": "medium",
    "13": "",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/0b4e6175-7305-4a7f-97d5-afde05901fe2.3d",
  },
  {
    "0": "OJP3/B/P1/12",
    "1": "B",
    "3": "P1",
    "4": 29.61,
    "5": 1,
    "6": "balkon",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-B-P1-12_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/1779fb61-48d0-49a1-8e95-fb7254cfa6e0.3d",
  },
  {
    "0": "OJP3/B/P1/13",
    "1": "B",
    "3": "P1",
    "4": 29.61,
    "5": 1,
    "6": "balkon",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-B-P1-13_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/1779fb61-48d0-49a1-8e95-fb7254cfa6e0.3d",
  },
  {
    "0": "OJP3/B/P1/14",
    "1": "B",
    "3": "P1",
    "4": 28.99,
    "5": 1,
    "6": "balkon",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-B-P1-14_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/1efed9b6-eb4a-4839-82be-5c1f35b81d31.3d",
  },
  {
    "0": "OJP3/B/P1/15",
    "1": "B",
    "3": "P1",
    "4": 38.4,
    "5": 1,
    "6": "balkon",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-B-P1-15_Umowa_Rezerwacyjna.pdf",
    "12": "medium",
    "13": "",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/f766241f-ba2f-4893-b0ef-67bff9c42872.3d",
  },
  {
    "0": "OJP3/B/P1/16",
    "1": "B",
    "3": "P1",
    "4": 45.46,
    "5": 2,
    "6": "balkon",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-B-P1-16_Umowa_Rezerwacyjna.pdf",
    "12": "medium",
    "13": "",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/b505a9f3-57fd-4548-afc5-962e3483408c.3d",
  },
  {
    "0": "OJP3/B/P1/17",
    "1": "B",
    "3": "P1",
    "4": 43.37,
    "5": 2,
    "6": "balkon",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-B-P1-17_Umowa_Rezerwacyjna.pdf",
    "12": "medium",
    "13": "",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/c9d24e10-57e9-4c85-9ab3-f6cf56d24559.3d",
  },
  {
    "0": "OJP3/B/P1/18",
    "1": "B",
    "3": "P1",
    "4": 42.73,
    "5": 2,
    "6": "balkon",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-B-P1-18_Umowa_Rezerwacyjna.pdf",
    "12": "medium",
    "13": "",
    "14": "residential",
    tour: "",
  },
  {
    "0": "OJP3/B/P1/19",
    "1": "B",
    "3": "P1",
    "4": 27.89,
    "5": 1,
    "6": "balkon",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-B-P1-19_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "true",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/ed7becd4-7d75-40d2-9dc3-6cd1fff8e513.3d",
  },
  {
    "0": "OJP3/B/P1/20",
    "1": "B",
    "3": "P1",
    "4": 40.78,
    "5": 2,
    "6": "balkon",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-B-P1-20_Umowa_Rezerwacyjna.pdf",
    "12": "medium",
    "13": "",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/5f32ecd6-d641-4a12-8643-368ec0c85d19.3d",
  },

  {
    "0": "OJP3/B/P2/21",
    "1": "B",
    "3": "P2",
    "4": 36.79,
    "5": 1,
    "6": "balkon",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-B-P2-21_Umowa_Rezerwacyjna.pdf",
    "12": "medium",
    "13": "",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/0b4e6175-7305-4a7f-97d5-afde05901fe2.3d",
  },
  {
    "0": "OJP3/B/P2/22",
    "1": "B",
    "3": "P2",
    "4": 29.61,
    "5": 1,
    "6": "balkon",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-B-P2-22_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/1779fb61-48d0-49a1-8e95-fb7254cfa6e0.3d",
  },
  {
    "0": "OJP3/B/P2/23",
    "1": "B",
    "3": "P2",
    "4": 29.61,
    "5": 1,
    "6": "balkon",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-B-P2-23_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/1779fb61-48d0-49a1-8e95-fb7254cfa6e0.3d",
  },
  {
    "0": "OJP3/B/P2/24",
    "1": "B",
    "3": "P2",
    "4": 28.99,
    "5": 1,
    "6": "balkon",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-B-P2-24_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/1efed9b6-eb4a-4839-82be-5c1f35b81d31.3d",
  },
  {
    "0": "OJP3/B/P2/25",
    "1": "B",
    "3": "P2",
    "4": 38.4,
    "5": 1,
    "6": "balkon",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-B-P2-25_Umowa_Rezerwacyjna.pdf",
    "12": "medium",
    "13": "",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/f766241f-ba2f-4893-b0ef-67bff9c42872.3d",
  },
  {
    "0": "OJP3/B/P2/26",
    "1": "B",
    "3": "P2",
    "4": 45.46,
    "5": 2,
    "6": "balkon",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-B-P2-26_Umowa_Rezerwacyjna.pdf",
    "12": "medium",
    "13": "",
    "14": "residential",
    // price: '408 551 zł',
    tour:
      "https://tours.3destate.pl/Process/b505a9f3-57fd-4548-afc5-962e3483408c.3d",
  },
  {
    "0": "OJP3/B/P2/27",
    "1": "B",
    "3": "P2",
    "4": 37.64,
    "5": 2,
    "6": "balkon",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-B-P2-27_Umowa_Rezerwacyjna.pdf",
    "12": "medium",
    "13": "",
    "14": "residential",
    price: '363 129 zł',
    tour:
      "https://tours.3destate.pl/Process/1d43d6a3-08c2-4b71-a3ce-28fafa461f00.3d",
  },
  {
    "0": "OJP3/B/P2/28",
    "1": "B",
    "3": "P2",
    "4": 42.8,
    "5": 2,
    "6": "balkon",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-B-P2-28_Umowa_Rezerwacyjna.pdf",
    "12": "medium",
    "13": "",
    "14": "residential",
    tour: "",
  },
  {
    "0": "OJP3/B/P2/29",
    "1": "B",
    "3": "P2",
    "4": 28.7,
    "5": 1,
    "6": "balkon",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-B-P2-29_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/14169dcc-bd79-496a-92f9-82fc2f254db9.3d",
  },
  {
    "0": "OJP3/B/P2/30",
    "1": "B",
    "3": "P2",
    "4": 40.78,
    "5": 2,
    "6": "balkon",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-B-P2-30_Umowa_Rezerwacyjna.pdf",
    "12": "medium",
    "13": "",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/5f32ecd6-d641-4a12-8643-368ec0c85d19.3d",
  },

  {
    "0": "OJP3/B/P3/31",
    "1": "B",
    "3": "P3",
    "4": 44.9,
    "5": 2,
    "6": "taras",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-B-P3-31_Umowa_Rezerwacyjna.pdf",
    "12": "medium",
    "13": "",
    "14": "residential",
    tour: "",
  },
  {
    "0": "OJP3/B/P3/32",
    "1": "B",
    "3": "P3",
    "4": 28.5,
    "5": 1,
    "6": "balkon",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-B-P3-32_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/d442f216-8c3c-408f-b3d8-d4d0e2ec2383.3d",
  },
  {
    "0": "OJP3/B/P3/33",
    "1": "B",
    "3": "P3",
    "4": 29.61,
    "5": 1,
    "6": "balkon",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-B-P3-33_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/1779fb61-48d0-49a1-8e95-fb7254cfa6e0.3d",
  },
  {
    "0": "OJP3/B/P3/34",
    "1": "B",
    "3": "P3",
    "4": 29.61,
    "5": 1,
    "6": "balkon",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-B-P3-34_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/1779fb61-48d0-49a1-8e95-fb7254cfa6e0.3d",
  },
  {
    "0": "OJP3/B/P3/35",
    "1": "B",
    "3": "P3",
    "4": 48.27,
    "5": 2,
    "6": "dwa balkony",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-B-P3-35_Umowa_Rezerwacyjna.pdf",
    "12": "medium",
    "13": "true",
    "14": "residential",
    tour: "",
  },
  {
    "0": "OJP3/B/P3/36",
    "1": "B",
    "3": "P3",
    "4": 37.65,
    "5": 2,
    "6": "balkon",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-B-P3-36_Umowa_Rezerwacyjna.pdf",
    "12": "medium",
    "13": "",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/d3d1bd5c-648f-42c8-8ab5-6262a2cc2658.3d",
  },
  {
    "0": "OJP3/B/P3/37",
    "1": "B",
    "3": "P3",
    "4": 42.8,
    "5": 2,
    "6": "balkon",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-B-P3-37_Umowa_Rezerwacyjna.pdf",
    "12": "medium",
    "13": "",
    "14": "residential",
    tour: "",
  },
  {
    "0": "OJP3/B/P3/38",
    "1": "B",
    "3": "P3",
    "4": 27.89,
    "5": 1,
    "6": "balkon",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-B-P3-38_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/10ff5cb0-d265-472a-9db5-fbd833a93830.3d",
  },
  {
    "0": "OJP3/B/P3/39",
    "1": "B",
    "3": "P3",
    "4": 40.78,
    "5": 2,
    "6": "taras",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-B-P3-39_Umowa_Rezerwacyjna.pdf",
    "12": "medium",
    "13": "",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/6e2898ae-3491-4367-bff7-c1197c2b9fcb.3d",
  },
  {
    "0": "OJP3/B/P3/40",
    "1": "B",
    "3": "P3",
    "4": 39.24,
    "5": 2,
    "6": "taras",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-B-P3-40_Umowa_Rezerwacyjna.pdf",
    "12": "medium",
    "13": "true",
    "14": "residential",
    tour: "",
  },

  {
    "0": "OJP3/C/P0/01",
    "1": "C",
    "3": "P0",
    "4": 37.37,
    "5": 2,
    "6": "ogródek",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-C-P0-01_Umowa_Rezerwacyjna.pdf",
    "12": "medium",
    "13": "true",
    "14": "residential",
    tour: "",
  },
  {
    "0": "OJP3/C/P0/02",
    "1": "C",
    "3": "P0",
    "4": 33.28,
    "5": 1,
    "6": "ogródek",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-C-P0-02_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/eeae2062-b91f-469f-bb42-84b997d626a4.3d",
  },
  {
    "0": "OJP3/C/P0/03",
    "1": "C",
    "3": "P0",
    "4": 29.61,
    "5": 1,
    "6": "ogródek",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-C-P0-03_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/c0c82127-e525-4e4a-895b-9e4010c7630b.3d",
  },
  {
    "0": "OJP3/C/P0/04",
    "1": "C",
    "3": "P0",
    "4": 34.8,
    "5": 1,
    "6": "ogródek",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-C-P0-04_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "true",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/9d29700e-bf91-44c3-8a8f-3eeb0326558f.3d",
  },
  {
    "0": "OJP3/C/P0/05",
    "1": "C",
    "3": "P0",
    "4": 53.3,
    "5": 3,
    "6": "ogródek",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-C-P0-05_Umowa_Rezerwacyjna.pdf",
    "12": "large",
    "13": "true",
    "14": "residential",
    // price: '449 611 zł',
    tour:
      "https://tours.3destate.pl/Process/71a1e289-1e15-4c3b-8e8f-5599a1e2766d.3d",
  },
  {
    "0": "OJP3/C/P0/06",
    "1": "C",
    "3": "P0",
    "4": 51.73,
    "5": 3,
    "6": "ogródek",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-C-P0-06_Umowa_Rezerwacyjna.pdf",
    "12": "large",
    "13": "true",
    "14": "residential",
    tour: "",
  },
  {
    "0": "OJP3/C/P0/07",
    "1": "C",
    "3": "P0",
    "4": 37.72,
    "5": 2,
    "6": "ogródek",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-C-P0-07_Umowa_Rezerwacyjna.pdf",
    "12": "medium",
    "13": "",
    "14": "residential",
    tour: "",
  },
  {
    "0": "OJP3/C/P1/08",
    "1": "C",
    "3": "P1",
    "4": 37.37,
    "5": 2,
    "6": "balkon",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-C-P1-08_Umowa_Rezerwacyjna.pdf",
    "12": "medium",
    "13": "",
    "14": "residential",
    tour: "",
  },
  {
    "0": "OJP3/C/P1/09",
    "1": "C",
    "3": "P1",
    "4": 33.28,
    "5": 1,
    "6": "balkon",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-C-P1-09_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/f1f52b0d-d81d-41ee-9dc3-c6e5954b9a6b.3d",
  },
  {
    "0": "OJP3/C/P1/10",
    "1": "C",
    "3": "P1",
    "4": 29.61,
    "5": 1,
    "6": "balkon",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-C-P1-10_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/1779fb61-48d0-49a1-8e95-fb7254cfa6e0.3d",
  },
  {
    "0": "OJP3/C/P1/11",
    "1": "C",
    "3": "P1",
    "4": 43.55,
    "5": 2,
    "6": "balkon",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-C-P1-11_Umowa_Rezerwacyjna.pdf",
    "12": "medium",
    "13": "",
    "14": "residential",
    price: '418 080 zł',
    tour:
      "https://tours.3destate.pl/Process/9248fb18-b859-400d-9106-146814dc1eb6.3d",
  },
  {
    "0": "OJP3/C/P1/12",
    "1": "C",
    "3": "P1",
    "4": 53.3,
    "5": 3,
    "6": "dwa balkony",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-C-P1-12_Umowa_Rezerwacyjna.pdf",
    "12": "large",
    "13": "true",
    "14": "residential",
    price: '477 061 zł',
    tour:
      "https://tours.3destate.pl/Process/1f0420e3-d743-41b7-b9f1-6ef6a35a1625.3d",
  },
  {
    "0": "OJP3/C/P1/13",
    "1": "C",
    "3": "P1",
    "4": 51.73,
    "5": 3,
    "6": "balkon",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-C-P1-13_Umowa_Rezerwacyjna.pdf",
    "12": "large",
    "13": "true",
    "14": "residential",
    tour: "",
  },
  {
    "0": "OJP3/C/P1/14",
    "1": "C",
    "3": "P1",
    "4": 37.76,
    "5": 2,
    "6": "balkon",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-C-P1-14_Umowa_Rezerwacyjna.pdf",
    "12": "medium",
    "13": "",
    "14": "residential",
    tour: "",
  },
  {
    "0": "OJP3/C/P2/15",
    "1": "C",
    "3": "P2",
    "4": 37.37,
    "5": 2,
    "6": "balkon",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-C-P2-15_Umowa_Rezerwacyjna.pdf",
    "12": "medium",
    "13": "true",
    "14": "residential",
    tour: "",
  },
  {
    "0": "OJP3/C/P2/16",
    "1": "C",
    "3": "P2",
    "4": 33.28,
    "5": 1,
    "6": "balkon",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-C-P2-16_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/f1f52b0d-d81d-41ee-9dc3-c6e5954b9a6b.3d",
  },
  {
    "0": "OJP3/C/P2/17",
    "1": "C",
    "3": "P2",
    "4": 29.61,
    "5": 1,
    "6": "balkon",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-C-P2-17_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/1779fb61-48d0-49a1-8e95-fb7254cfa6e0.3d",
  },
  {
    "0": "OJP3/C/P2/18",
    "1": "C",
    "3": "P2",
    "4": 43.55,
    "5": 2,
    "6": "balkon",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-C-P2-18_Umowa_Rezerwacyjna.pdf",
    "12": "medium",
    "13": "",
    "14": "residential",
    tour: "",
  },
  {
    "0": "OJP3/C/P2/19",
    "1": "C",
    "3": "P2",
    "4": 53.3,
    "5": 3,
    "6": "dwa balkony",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-C-P2-19_Umowa_Rezerwacyjna.pdf",
    "12": "large",
    "13": "true",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/1f0420e3-d743-41b7-b9f1-6ef6a35a1625.3d",
  },
  {
    "0": "OJP3/C/P2/20",
    "1": "C",
    "3": "P2",
    "4": 42.88,
    "5": 2,
    "6": "taras",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-C-P2-20_Umowa_Rezerwacyjna.pdf",
    "12": "medium",
    "13": "",
    "14": "residential",
    tour: "",
  },
  {
    "0": "OJP3/C/P2/21",
    "1": "C",
    "3": "P2",
    "4": 37.76,
    "5": 2,
    "6": "balkon",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-C-P2-21_Umowa_Rezerwacyjna.pdf",
    "12": "medium",
    "13": "true",
    "14": "residential",
    tour: "",
  },

  {
    "0": "OJP3/C/P3/22",
    "1": "C",
    "3": "P3",
    "4": 27.07,
    "5": 1,
    "6": "balkon",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-C-P3-22_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/5b50281f-fad6-4694-82f6-feb73930e378.3d",
  },
  {
    "0": "OJP3/C/P3/23",
    "1": "C",
    "3": "P3",
    "4": 55.21,
    "5": 3,
    "6": "dwa balkony",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-C-P3-23_Umowa_Rezerwacyjna.pdf",
    "12": "large",
    "13": "true",
    "14": "residential",
    tour: "",
  },
  {
    "0": "OJP3/C/P3/24",
    "1": "C",
    "3": "P3",
    "4": 29.61,
    "5": 1,
    "6": "balkon",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-C-P3-24_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/1779fb61-48d0-49a1-8e95-fb7254cfa6e0.3d",
  },
  {
    "0": "OJP3/C/P3/25",
    "1": "C",
    "3": "P3",
    "4": 43.55,
    "5": 2,
    "6": "balkon",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-C-P3-25_Umowa_Rezerwacyjna.pdf",
    "12": "medium",
    "13": "true",
    "14": "residential",
    tour: "",
  },
  {
    "0": "OJP3/C/P3/26",
    "1": "C",
    "3": "P3",
    "4": 53.3,
    "5": 3,
    "6": "dwa balkony",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-C-P3-26_Umowa_Rezerwacyjna.pdf",
    "12": "large",
    "13": "true",
    "14": "residential",
    tour:
      "https://tours.3destate.pl/Process/1f0420e3-d743-41b7-b9f1-6ef6a35a1625.3d",
  },
  {
    "0": "OJP3/C/P3/27",
    "1": "C",
    "3": "P3",
    "4": 42.88,
    "5": 2,
    "6": "balkon",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-C-P3-27_Umowa_Rezerwacyjna.pdf",
    "12": "medium",
    "13": "true",
    "14": "residential",
    tour: "",
  },
  {
    "0": "OJP3/C/P3/28",
    "1": "C",
    "3": "P3",
    "4": 37.76,
    "5": 2,
    "6": "balkon",
    "7": "II kwartał 2026",
    "11": "/assets/files/ojp3/pdf/OJP3-C-P3-28_Umowa_Rezerwacyjna.pdf",
    "12": "medium",
    "13": "",
    "14": "residential",
    tour: "",
  },
]
